:root {
  --viz-min-width: 500px;
  --viz-height: 700px;
  --legend-min-width: 300px;
}

div#cargo {
  grid-template-columns: 1fr;
  grid-template-rows: var(--viz-height) 1fr;
}

div#legend_guide {
  display: grid;
  grid-template-rows: 1fr;
  grid-template-columns: 1fr 1fr 1fr;
  margin-left: 20px
}

div#label-button {
  grid-template-rows: 1fr 1fr 1fr;
}

div#legend-switch {
  margin-top: 10px
}

@media only screen and (min-width: 1000px) {
  div#cargo {
    box-sizing: border-box;
    grid-template-columns: 1fr var(--legend-min-width);
    display: grid;
  }

  div#legend_guide {
    grid-template-columns: 1fr;
    grid-template-rows: 1fr 8fr 12fr;
  }
}

/* The switch - the box around the slider */
.switch {
  position: relative;
  display: inline-block;
  width: 36px;
  height: 20px;
  margin-top: 5px;
}

/* Hide default HTML checkbox */
.switch input {
  opacity: 0;
  width: 0;
  height: 0;
}

/* The slider */
.slider {
  position: absolute;
  cursor: pointer;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background-color: #ccc;
  -webkit-transition: 0.4s;
  transition: 0.4s;
}

.slider:before {
  position: absolute;
  content: "";
  height: 16px;
  width: 16px;
  left: 2px;
  bottom: 2px;
  background-color: white;
  -webkit-transition: 0.4s;
  transition: 0.4s;
}

input:checked + .slider {
  background-color: #5a5a5a;
}

input:focus + .slider {
  box-shadow: 0 0 1px #5a5a5a;
}

input:checked + .slider:before {
  -webkit-transform: translateX(16px);
  -ms-transform: translateX(16px);
  transform: translateX(16px);
}

/* Rounded sliders */
.slider.round {
  border-radius: 17px;
}

.slider.round:before {
  border-radius: 50%;
}

#legend_guide {
  text-align: left;
}

div#legend-stroke,
div#legend-color,
div#label-button {
  grid-template-rows: 1fr 1fr 1fr;
}

.legendTitle {
  font-size: 14px;
  color: gray;
  font-family: Helvetica;
  position: relative;
  bottom: 3px;
}

.mybutton {
  border-radius: 20px !important;
  font-size: 14px !important;
  padding: 10px;
  border: lightgray;
  color: gray;
  background-color: lightgray !important;
}
