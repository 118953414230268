.footer-container {
    border-radius: 16px 16px 0 0;
    margin-top: 20px;
    height: fit-content;
    width: 100%;
    justify-content: space-between;
    padding: 10px 30px 10px 30px;
}

.footer-div {
    width: 100%;
    font-size: 0.875rem
}

.social-media {
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;
    justify-content: center;
    margin-top: 15px;
}

.img-logo {
    max-height: 70px;
    min-height: 70px;
    border-radius: 35%;
    width: auto;
}

.socialMediaLogo {
    margin-left: 22px;
    margin-right: 22px;
}

.stay-connected-title {
    font-family: 'Arial', sans-serif;
    text-align: left;
    letter-spacing: 2px;
    width: 100%;
    padding: 16px;
}

.stay-connected-shim {
    height: 32px;
}

.description {
    font-family: 'Arial', sans-serif;
    letter-spacing: 1px;
    margin-right: 30px;
    margin-bottom: 10px;
    text-align: justify;
}

.resources {
    font-family: 'Arial', sans-serif;
    letter-spacing: 1px;
    color: black;
}

.copyright {
    text-align: left;
    margin-top: 10px;
    letter-spacing: 1px;
}

.email-subscribe {
    display: inline-block;
}

.license-row {
    font-family: 'Arial', sans-serif;
    text-align: left;
    letter-spacing: 2px;
    width: 100%;
    padding: 16px 16px 0 16px;
}

.copyright-row {
    font-family: 'Arial', sans-serif;
    text-align: left;
    letter-spacing: 2px;
    width: 100%;
    padding: 0 16px 16px 16px;
}